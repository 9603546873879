<template>
  <v-container style="max-width:1400px;">
    <v-row align="start" justify="start" class="pb-8">
      <!-- title -->
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <v-card flat tile v-if="displayUserAfterFilter"
          style="border-radius:8px;" >
          <v-card-title
            class="py-1"
            style="font-size:24px;color:teal;border-bottom:1px solid teal;"
          >
            <v-btn icon color="teal" @click="goBack" class="mr-2">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            Hồ sơ cá nhân
            <v-spacer />
            <v-btn
              class="my-1 mr-2"
              style="min-width:90px;"
              color="red"
              dark
              @click="callDeleteUser"
            >
              Xóa
            </v-btn>
            <v-btn
              class="my-1"
              style="min-width:90px;"
              color="primary"
              dark
              @click="callUpdateUser"
            >
              Lưu
            </v-btn>
          </v-card-title>
          
        </v-card>
      </v-col>

      <!-- thong tin nhan vien chi tiet -->
      <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" class="px-2 pt-1">
        <v-card flat style="border-radius:8px;">
          <v-card-text class="pl-3 pr-2 py-2">
            <div
              v-if="displayUserAfterFilter"
              style="width:100%;font-size:14px;line-height:1.2;"
            >
              <div class="d-flex align-center py-1">
                <div style="width:92px;" class="d-flex-inline">
                  Mã NV:
                </div>
                <div style="width:280px;" class="d-flex-inline">
                  <v-text-field v-model="user.user_code"
                    flat dense outlined
                    :rules="[rules.required]"
                    style='font-size: 15px;'
                    single-line hide-details
                  ></v-text-field>
                </div>
              </div>
              

              <div>
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Họ và Tên:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.fullname"
                        flat dense outlined
                        :rules="[rules.required, rules.fullname]"
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Giới tính:
                    </div>
                    <div style="width:120px;" class="d-flex-inline">
                      <v-select
                        v-model="user.gioitinh"
                        :items="gioitinhs"
                        outlined dense style="font-size:14px;"
                        single-line hide-details
                      ></v-select>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Dân tộc:
                    </div>
                    <div style="width:120px;" class="d-flex-inline">
                      <v-text-field v-model="user.dantoc"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Ngày sinh:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <date-input v-model='user.ngaysinh'/>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Nơi sinh:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.noisinh"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      CMND:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.cmnd"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Ngày cấp:
                    </div>
                    <div style="width:120px;" class="d-flex-inline">
                      <date-input v-model='user.cmnd_ngay_cap'/>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Nơi cấp:
                    </div>
                    <div style="width:120px;" class="d-flex-inline">
                      <v-text-field v-model="user.cmnd_noi_cap"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-inline-block" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Đc thường trú:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.diachi_thuongtru"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="d-inline-block" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Đc tạm trú:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.diachi_tamtru"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Ngày vào làm:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <date-input v-model='user.ngay_vaolam'/>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Trạng thái:
                    </div>
                    <div style="width:120px;" class="d-flex-inline">
                      <v-select
                        v-model="user.trangthai_congviec"
                        :items="trangthai_congviecs"
                        outlined dense style="font-size:12px;"
                        single-line hide-details
                      ></v-select>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Ngày nghỉ việc:
                    </div>
                    <div style="width:120px;" class="d-flex-inline">
                      <date-input v-model='user.ngay_nghiviec'/>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Công ty:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.congty"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Khối:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.khoi"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Phòng ban:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-select
                        v-model="user.department"
                        :items="departments"
                        item-value="_id"
                        item-text="name"
                        color="#DD0011"
                        :rules="[rules.required]"
                        outlined dense style="font-size:14px;"
                        single-line hide-details
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Chức vụ:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.chucvu"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Vị trí:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.position"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Email:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.email"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>

                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      SĐT:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.mobile"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Nơi làm việc:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.work_at"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>

              <div class="align-center">
                <div class="d-inline-block pr-3" style="vertical-align:top;">
                  <div class="d-flex align-center py-1">
                    <div style="width:92px;" class="d-flex-inline">
                      Cấp mã vào cửa:
                    </div>
                    <div style="width:280px;" class="d-flex-inline">
                      <v-text-field v-model="user.mavaocua"
                        flat dense outlined
                        style='font-size: 14px;'
                        single-line hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>



              <!--
              <div class="mt-2">
                <div style="width:92px;;display:inline-block;">
                  Ngày tạo
                </div>
                <div style="width:320px;display:inline-block;">
                  {{
                    $moment(displayUserAfterFilter.createdDate).format(
                      "HH:mm DD-MM-YYYY"
                    )
                  }}
                </div>
              </div>
              -->
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- avatar and file check list -->
      <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="px-2 pt-1">
        <!-- avatar -->
        <v-card flat style="border-radius:8px;">
          <v-card-text class="px-4 py-2">
            <div
              v-if="displayUserAfterFilter"
              style="width:100%;font-size:15px;"
            >
              <div class="d-flex align-center">
                <div class="d-flex-inline">
                  <b>Ảnh đại diện</b>
                </div>

                <div class="d-flex-inline pb-1 pt-1">
                  <input
                    type="file"
                    style="display: none"
                    ref="imageInput"
                    accept="*/*"
                    @change="handleFileUpload"
                  />

                  <v-btn
                    @click="startPickImage"
                    class="ml-2 text-none px-2"
                    color="teal"
                    outlined
                    style="min-width:20px;height:32px;"
                  >
                    <v-icon>
                      add_photo_alternate
                    </v-icon>
                    Upload ảnh
                  </v-btn>
                </div>
              </div>

                <div>
                  <v-img
                    contain
                    style="border:1px solid #DDDDDD;width:100%;max-height:200px;"
                    :src="
                      displayUserAfterFilter.avatar
                        ? displayUserAfterFilter.avatar
                        : '/imgs/user-placehold.jpg'
                    "
                  />
                </div>
              </div>
          </v-card-text>
        </v-card>
        <!-- file checklist -->
        <v-card flat class="mt-1" style="border-radius:8px;">
          <v-card-title class="py-1">
            Hồ sơ cá nhân
          </v-card-title>
          <v-card-text class="px-4 py-1" v-if="user">
            <v-checkbox v-model="user.check_donxinviec"
              label="Đơn xin việc"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>

            <v-checkbox v-model="user.check_soyeulilich"
              label="Sơ yếu lý lịch (có xác nhận địa phương)"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>

            <v-checkbox v-model="user.check_giaykhamsuckhoe"
              label="Giấy khám sức khỏe (trong vào 6 tháng)"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>

            <v-checkbox v-model="user.check_giaykhaisinh"
              label="Giấy khai sinh (công chứng)"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>

            <v-checkbox v-model="user.check_bangcap"
              label="Bằng cấp có liên quan (công chứng)"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>

            <v-checkbox v-model="user.check_cmnd"
              label="CMND (công chứng)"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>

            <v-checkbox v-model="user.check_sohokhau"
              label="Sổ hộ khẩu (công chứng)"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>

            <v-checkbox v-model="user.check_anh34"
              label="3 ảnh 3x4"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- new line -->
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0">
      </v-col>

      <!-- ban giao nhan su -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 my-0">
        <v-card flat class="mt-1" style="border-radius:8px;">
          <v-card-title class="py-1">
            Bàn giao nhân sự
          </v-card-title>
          <v-card-text class="px-4 py-1" v-if="user">

            <div class="d-flex align-center py-1">
              <div style="width:280px;" class="d-flex-inline">
                Ngày nhận đơn xin thôi việc:
              </div>
              <div style="width:140px;" class="d-flex-inline">
                <date-input v-model='user.date_donxin_thoiviec'/>
              </div>
            </div>

            <div class="d-flex align-center py-1">
              <div style="width:280px;" class="d-flex-inline">
                Ngày nhận biên bản bàn giao công việc:
              </div>
              <div style="width:140px;" class="d-flex-inline">
                <date-input v-model='user.date_bangiao_congviec'/>
              </div>
            </div>

            <div class="d-flex align-center py-1">
              <div style="width:280px;" class="d-flex-inline">
                Ngày bàn giao cho pháp chế:
              </div>
              <div style="width:140px;" class="d-flex-inline">
                <date-input v-model='user.date_bangiao_phapche'/>
              </div>
            </div>

            <div class="d-flex align-center py-1">
              <div style="width:280px;" class="d-flex-inline">
                Ngày bàn giao QĐCD, BHXH cho NLĐ:
              </div>
              <div style="width:140px;" class="d-flex-inline">
                <date-input v-model='user.date_bangiao_bhxh'/>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-col>

      <!-- ban giao nhan su -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="py-0 my-0">
        <v-card flat class="mt-1" style="border-radius:8px;">
          <v-card-title class="py-1">
            Văn bản nhân sự
            <v-spacer/>
            <v-btn text color="primary" class="text-none" @click='showAddVanBan'>
              Thêm
            </v-btn>
          </v-card-title>
          <v-card-text class="px-4 py-1" v-if="user">
            <v-dialog width="840px" v-model='dialogVanban' persistent>
              <mcv-edit-van-ban :isCreate='isCreateVanBan' :vanban='activeVanBan' :departments='departments' 
                @cancel='dialogVanban=false' @addNew='addVanBan' @update="updateVanban"/>
            </v-dialog>
            <div v-if="!user.vanbans || user.vanbans.length<=0" class="text-center">
              <i>Chưa có</i>
            </div>
            <div v-if='user.vanbans && user.vanbans.length>0'>
              <div v-for="(vanban, index) in user.vanbans" :key='index'
                class="py-1"
                style='font-size:13px;border-bottom: 1px solid #CCCCCC;'>

                  <!--
                    <div class="d-flex align-center py-1">
                        <div style="width:100px;" class="d-flex-inline">
                          Công ty:
                        </div>
                        <div style="width:280px;" class="d-flex-inline">
                          {{vanban.congty}}
                        </div>
                    </div>

                    <div>
                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center py-1">
                              <div style="width:100px;" class="d-flex-inline">
                                Khối:
                              </div>
                              <div style="width:280px;" class="d-flex-inline">
                                {{vanban.khoi}}
                              </div>
                          </div>
                        </div>

                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center py-1">
                              <div style="width:100px;" class="d-flex-inline">
                                Phòng ban:
                              </div>
                              <div style="width:280px;" class="d-flex-inline">
                                {{vanban.department}}
                              </div>
                          </div>
                        </div>
                    </div>

                    <div>
                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center py-1">
                              <div style="width:100px;" class="d-flex-inline">
                                Chức vụ:
                              </div>
                              <div style="width:280px;" class="d-flex-inline">
                                {{vanban.chucvu}}
                              </div>
                          </div>
                        </div>

                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center py-1">
                              <div style="width:100px;" class="d-flex-inline">
                                Vị trí:
                              </div>
                              <div style="width:280px;" class="d-flex-inline">
                                {{vanban.vitri}}
                              </div>
                          </div>
                        </div>
                    </div>
                  -->
                    <div class="d-flex align-center pb-0">
                        <div style="width:100px;" class="d-flex-inline">
                          Tên văn bản:
                        </div>
                        <div style="" class="d-flex-inline">
                          <b>{{vanban.ten}}</b>
                          <v-btn text color='primary' class='ml-3 px-1 text-none' style='height:26px;' @click="showEditVanBan(vanban, index)">
                            Chỉnh sửa
                          </v-btn>
                          <v-btn text color='red' class='ml-1 px-1 text-none' style='height:26px;min-width:48px;' @click="xoaVanBan(index)">
                            Xóa
                          </v-btn>
                        </div>
                    </div>

                    <div>
                      <div class="d-inline-block pr-3" style="vertical-align:top;">
                        <div class="d-flex align-center pb-0">
                            <div style="width:100px;" class="d-flex-inline">
                              Loại văn bản:
                            </div>
                            <div style="width:120px;" class="d-flex-inline">
                              {{vanban.loai}}
                            </div>
                        </div>
                      </div>
                      <div class="d-inline-block pr-3" style="vertical-align:top;">
                        <div class="d-flex align-center pb-0">
                            <div style="width:100px;" class="d-flex-inline">
                              Thời hạn:
                            </div>
                            <div style="width:120px;" class="d-flex-inline">
                              {{vanban.thoihan}}
                            </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="d-inline-block pr-3" style="vertical-align:top;">
                        <div class="d-flex align-center pb-0">
                            <div style="width:100px;" class="d-flex-inline">
                              Ngày đề xuất:
                            </div>
                            <div style="width:120px;" class="d-flex-inline">
                              {{vanban.ngaydexuat?$moment(vanban.ngaydexuat).format('DD/MM/YYYY'):''}}
                            </div>
                        </div>
                      </div>
                      <div class="d-inline-block pr-3" style="vertical-align:top;">
                        <div class="d-flex align-center pb-0">
                            <div style="width:100px;" class="d-flex-inline">
                              Ngày ký:
                            </div>
                            <div style="width:120px;" class="d-flex-inline">
                              {{vanban.ngayky?$moment(vanban.ngayky).format('DD/MM/YYYY'):''}}
                            </div>
                        </div>
                      </div>
                    </div>

                    <div>
                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center pb-0">
                              <div style="width:100px;" class="d-flex-inline">
                                Ngày hiệu lực:
                              </div>
                              <div style="width:120px;" class="d-flex-inline">
                                {{vanban.ngayhieuluc?$moment(vanban.ngayhieuluc).format('DD/MM/YYYY'):''}}
                              </div>
                          </div>
                        </div>

                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center pb-0">
                              <div style="width:100px;" class="d-flex-inline">
                                Ngày hết hạn:
                              </div>
                              <div style="width:120px;" class="d-flex-inline">
                                {{vanban.ngayhethan?$moment(vanban.ngayhethan).format('DD/MM/YYYY'):''}}
                              </div>
                          </div>
                        </div>

                        
                    </div>

                    <div>
                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center pb-0">
                              <div style="width:100px;" class="d-flex-inline">
                                Trạng thái:
                              </div>
                              <div style="width:240px;" class="d-flex-inline">
                                {{vanban.trangthai}}
                              </div>
                          </div>
                        </div>
                        <div class="d-inline-block pr-3" style="vertical-align:top;">
                          <div class="d-flex align-center pb-0">
                              <div style="width:100px;" class="d-flex-inline">
                                Lý do:
                              </div>
                              <div style="width:280px;" class="d-flex-inline">
                                {{vanban.lydo}}
                              </div>
                          </div>
                        </div>
                    </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- hoàn tất thủ tục nhân sự -->
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 mt-6 mb-4">
        <v-card flat class="mt-1" style="border-radius:8px;">
          <v-card-text class="px-4 py-3" v-if="user">
            <v-checkbox v-model="user.check_hoantat_thutuc_nhansu"
              label="Hoàn tất thủ tục nhân sự" color="green"
              hide-details class="ma-0 pa-1 pb-2"
            ></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- new line -->
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="py-0 my-0">
        <v-btn class="text-none" color="primary" @click='showCapPhepVaoCua=!showCapPhepVaoCua'>
          {{showCapPhepVaoCua?'Ẩn cấp phép vào cửa':'Xem cấp phép vào cửa'}}
        </v-btn>
      </v-col>

      <template v-if="showCapPhepVaoCua">  
        
        <!-- Cách thức vào cửa -->
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <v-card flat style="border-radius:8px;">
            <v-card-text class="px-1 py-1" v-if="user">
                <div class="mt-4"
                  style="color:teal;font-weight:700;font-size:16px;border-bottom:1px solid teal;">
                  Cách thức vào cửa:
                </div>

                <div class="mt-2">
                  <div style="width:92px;;display:inline-block;">
                    Mật khẩu:
                  </div>
                  <div style="width:160px;display:inline-block;">
                    <v-text-field
                      v-if="user.zk_access"
                      flat
                      dense
                      v-model="user.zk_access.password"
                      color="#DD0011"
                      clearable
                      outlined
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>

                <div class="mt-2">
                  <div style="width:92px;;display:inline-block;">
                    Mã Thẻ:
                  </div>
                  <div style="width:160px;display:inline-block;">
                    <v-text-field
                      flat
                      dense
                      v-model="user.zk_access.card_no"
                      color="#DD0011"
                      clearable
                      outlined
                      single-line
                      hide-details
                      placeholder=""
                    ></v-text-field>
                  </div>
                </div>

                <div class="mt-2">
                  <div style="width:92px;;display:inline-block;vertical-align:top;">
                    Vân tay:
                  </div>
                  <div style="width:300px;display:inline-block;vertical-align:top;">
                    <div v-for="finger in user.zk_access.fingers"
                      :key="finger.index"
                      style="display:inline-block;">
                      <v-icon  
                        :color="finger.template.length>0?'teal':'#AAAAAA44'"
                        >
                          mdi-fingerprint
                      </v-icon>
                      <div class="text-center" style="font-size:10px;">
                        {{finger.index+1}}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-2">
                  <div style="width:92px;;display:inline-block;vertical-align:top;">
                    FaceId:
                  </div>
                  <div style="width:300px;display:inline-block;vertical-align:top;">
                    <div v-for="face in user.zk_access.faces"
                      :key="face.index"
                      style="display:inline-block;">
                      <v-icon  
                        :color="face.template.length>0?'teal':'#AAAAAA44'"
                        >
                          mdi-guy-fawkes-mask
                      </v-icon>
                      <div class="text-center" style="font-size:10px;">
                        {{face.index + 1}}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="1==2">
                  <v-btn color="primary"
                    class="text-none"
                    @click="dialogUser=true">
                    Sao chép vân tay/faceId từ User khác
                  </v-btn>
                </div>
                <v-dialog v-model="dialogUser"
                  max-width="460px">
                  <dialog-select-user 
                    @closeMe="dialogUser=false"
                    @onUserSelected="onOneUserPick"/>
                </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
        
        <!-- thong tin vao tung cua -->
        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
          class="mt-2"
          v-if="displayUserAfterFilter && displayUserAfterFilter.user_code">
          <div v-if="1==2">
            <v-btn text color="primary"
              class="text-none"
              @click="reuquestReadFullDetailAllDoor">
              Tải thông tin chi tiết từng cửa
            </v-btn>
          </div>

          <template v-if="showAllDoor"> 
            <v-row  v-for="(floor, index) in floors" :key="index" 
              class="mb-6 mt-2"
              style="background-color:F2F2F2;">
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                class="py-1"
                style="color:#01579B;font-size:16px;font-weight:600;border-bottom: 1px solid #01579B;">
                {{floor.name}}
              </v-col>
              <v-col v-for="(door, dIndex) in floor.doors" :key="dIndex"
                cols="6" xs="6" sm="6" md="4" lg="4" xl="4"
                class="py-0 pt-2"
                :class="{
                  'px-1': $vuetify.breakpoint.smAndDown,
                  'px-2': $vuetify.breakpoint.mdAndUp,
                  }"
                >
                  <user-door-state
                    :door="door"
                    :user="user"
                    :userId="displayUserAfterFilter.user_code"
                    :zkAccess="displayUserAfterFilter.zk_access" />
              </v-col>
            </v-row>
          </template>


        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import DialogSelectUser from "./DialogSelectUser.vue";

export default {
  computed: {
    ...mapGetters({
      token: "token",
    }),
    displayUserAfterFilter() {
      let result = null;
      if (!this.user) return null
      result = JSON.parse(JSON.stringify(this.user));
      if(!result.zk_acccess){
        result.zk_acccess = {
          user_id: '',
          password: '',
          card_no: '',
          enable: true,
          fingers: [],
          faces: [],
        }
      }
      return result
    },
  },
  components: { DialogSelectUser },
  data() {
    return {
      roles: ['user-manager', 'admin'],
      user: null,
      departments: [],
      gioitinhs: ['Nam', 'Nữ', 'Khác'],
      trangthai_congviecs: ['Đang làm việc', 'Nghỉ việc'],
      showCapPhepVaoCua: false,
      editPwdMode: false,
      socketAskKey: new Date().getTime(),
      showAllDoor: true,
      rules: {
        required: (value) => !!value || "* Required",
        passRule: (v) => (v && v.length >= 6) || "Min 6 chars",
        username: (v) => (v && v.length >= 6) || "Min 6 chars",
        fullname: (v) => (v && v.length >= 3) || "Min 3 chars",
        email: (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      },
      floors: require('@/mkdata/floors').floors,
      userHeaders: [
        {
          text: "Mã NV",
          align: "start",
          sortable: true,
          value: "user_code",
          width: 100,
        },
        { text: "Tên", align: "start", sortable: true, value: "fullname" },
      ],
      dialogUser: false,
      dialogVanban: false,
      activeVanBan: null,
      isCreateVanBan: true,
      activeVanBanIndex: -1

    }
  },
  sockets: {
      'setUserInfo-REPLY': function (payload) {
         this.loading = false
         if(payload && payload.askKey == this.socketAskKey){
            this.result = payload.result
            // console.log('setUserInfo-REPLY')
            // console.log(this.result)
         }
      }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    startPickImage() {
      this.$refs.imageInput.click();
    },
    handleFileUpload: function() {
      var file = this.$refs.imageInput.files[0];
      if (file !== undefined) {
        var self = this;
        self.result = "";
        let formData = new FormData();
        formData.append("file", file);
        self.axios
          .post("https://file.ponotek.com/uploads", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function(res) {
            self.user.avatar = res.data.content;
          })
          .catch(function(err) {
            console.log(err);
          });
      }
    },
    showAddVanBan() {
      this.activeVanBan = {
        congty: '',
        khoi: '',
        phongban: null,
        chucvu: '',
        vitri: '',
        ten: '',
        loai: '',
        thoihan: '',
        ngayky: null,
        ngayhieuluc: null,
        ngayhethan: null,
        ngaydexuat: null,
        trangthai: '',
        lydo: '',
        files: [],
      }
      this.dialogVanban = true
    },
    addVanBan (vanban) {
      if(!this.user.vanbans) this.user.vanbans = []
      this.user.vanbans.push(JSON.parse(JSON.stringify(vanban)))
      this.activeVanBan = null
      this.isCreateVanBan = true
      this.dialogVanban = false
    },
    showEditVanBan(vanban, index){
      this.activeVanBan = JSON.parse(JSON.stringify(vanban))
      this.activeVanBanIndex = index
      this.isCreateVanBan = false
      this.dialogVanban = true
    },
    xoaVanBan(index){
      if (index > -1) {
        this.user.vanbans.splice(index, 1)
      }
    },
    updateVanban(vanban) {
      let tmpVanBan = JSON.parse(JSON.stringify(vanban))
      this.user.vanbans[this.activeVanBanIndex] = tmpVanBan
      this.dialogVanban = false
    },
    callDeleteUser() {
      let self = this;
      this.axios
        .put(
          this.$root.apiUser + "/users/delete/" + self.user._id,
          {},
          {
            headers: {
              "x-auth": this.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            alert("Xóa thành công thành công!");
            window.location = '/users'
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
    callUpdateUser() {
      if (!this.user) return;
      if (!this.user.user_code || this.user.user_code.length <= 0) {
        alert("Mã nhân viên không được để trống!");
        return;
      }
      if (!this.user.fullname || this.user.fullname.length <= 0) {
        alert("Họ và tên không được để trống!");
        return;
      }
      if (!this.user.department || this.user.department.length <= 0) {
        alert("Phòng ban không được để trống!");
        return;
      }
      if (!this.user.mobile != true && this.user.mobile.length < 8) {
        alert("Số điện thoại quá ngắn!");
        return;
      }

      let userId = this.user.user_code.replace("NV","")
      if(!userId.includes("T")){
        userId = "" + Number(userId)
      }

      this.requestDeviceToUpdateNewInfo(
          userId, 
          this.user.fullname,
          this.user.zk_access.password,
          this.user.zk_access.card_no)

      let self = this;
      this.axios
        .put(
          this.$root.apiUser + "/users/update/" + self.user._id,
          this.user,
          // {
          //   user_code: this.user.user_code,
          //   fullname: this.user.fullname,
          //   department: this.user.department,
          //   position: this.user.position,
          //   work_at: this.user.work_at,
          //   email: this.user.email,
          //   mobile: this.user.mobile,
          //   avatar: this.user.avatar,
          //   zk_acccess: this.user.zk_acccess,
          // },
          {
            headers: {
              "x-auth": this.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "OK") {
            self.user = response.data.content;
            alert("Cập nhật thành công!");
          } else {
            alert("Lỗi! " + response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Lỗi! " + err.message);
        });
    },
    processAfterFetchDataUser() {
      if (this.user) {
        //Departments
        if (!this.departments.find((i) => i._id == this.user.department)) {
          this.user.department_name = "-";
        } else {
          this.user.department_name = this.departments.find(
            (i) => i._id == this.user.department
          ).name;
        }
      }
    },
    fetchUser() {
      let self = this;
      let pathFetchDataUser =
        self.$root.apiUser + "/users/get/" + this.$route.params.user_id;
      let pathFetchDataDepartments =
        self.$root.apiUser + "/depts/list/" + self.$root.apiUserCustomId;

      Promise.all([
        self.axios.get(pathFetchDataUser),
        self.axios.get(pathFetchDataDepartments),
      ])
        .then((responseArray) => {
          // console.log("Promise ALL", responseArray);
          if (responseArray[0].data.status == "OK") {
            self.user = responseArray[0].data.content;
            if(self.user){
              self.user.check_donxinviec = !!self.user.check_donxinviec
              self.user.check_soyeulilich = !!self.user.check_soyeulilich
              self.user.check_giaykhamsuckhoe = !!self.user.check_giaykhamsuckhoe
              self.user.check_giaykhaisinh = !!self.user.check_giaykhaisinh
              self.user.check_bangcap = !!self.user.check_bangcap
              self.user.check_cmnd = !!self.user.check_cmnd
              self.user.check_sohokhau = !!self.user.check_sohokhau
              self.user.check_anh34 = !!self.user.check_anh34
            }
          } else {
            alert(responseArray[0].data.message)
          }
          if (responseArray[1].data.status == "OK") {
            self.departments = responseArray[1].data.content.items;
          } else {
            alert(responseArray[1].data.message)
          }
          self.processAfterFetchDataUser()
          // self.fetchUsers()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    requestDeviceToUpdateNewInfo(userId, name, password, card_no ){
      this.floors.forEach(floor => {
        floor.ips.forEach(ip => {
          // console.log("IO Send requestUpdateUserInfo")
          let payload = {
            command: "requestUpdateUserInfo",
            askKey: this.socketAskKey,
            devCode: "MCVZK-" + ip,
            paras: {
                userId: userId,
                name: name || "",
                card_no: card_no || "",
                password: password || ""
            }
          }
          // console.log(payload)
          this.$socket.emit('sendMagicCommand', payload)
        })
      })
    },
    reuquestReadFullDetailAllDoor () {
      this.showAllDoor = true
      this.$emit('requestChildReadUserFull')
      // let self = this
      // setTimeout(function(){
      //   self.$emit('requestChildReadUserFull')
      // }, 500)
    },
    onOneUserPick (pickedUser) {
      this.dialogUser = false
      console.log(pickedUser)
      if(confirm('Xác nhận copy thông tin vân tay/faceid từ user ' + pickedUser.fullname + ' vào user ' + this.user.fullname + '?')) {
        this.fetchUserById(pickedUser._id).then(user => {
          this.user.zk_access = user.zk_acccess
          console.log(user)
        }).catch(console.log)
        // alert('Copy xong!')
        this.$forceUpdate()
      }
    },
    fetchUserById(userId) {
      return new Promise((resolve, reject) => {
        let self = this;
        let pathFetchDataUser =
          self.$root.apiUser + "/users/get/" + userId
        this.axios.get(pathFetchDataUser).then(res => {
          if (res.data.status == "OK") {
              resolve(res.data.content)
            }
          else { reject(res.data.status)}
        }).catch(reject)
      })
    }
  },
  created () {
    this.$nextTick(() => {
      this.fetchUser()
    });
  },
};
</script>

<style></style>
