exports.floors = [

   {
      name: "Trệt",
      ips: ["192.168.1.101"],
      doors: [
         {
            name: "Mnet Studio - Trệt",
            ip: "192.168.1.101",
         }
      ]
   },

   {
      name: "VP Lầu 2",
      ips: ["192.168.1.102", "192.168.1.104", "192.168.1.100"],
      doors: [
         {
            name: "Cửa chính",
            ip: "192.168.1.102",
         },
         {
            name: "Cửa phụ",
            ip: "192.168.1.104",
         },
         {
            name: "Cửa sau",
            ip: "192.168.1.100",
         }
      ]
   },

   {
      name: "VP Lầu 3",
      ips: ["192.168.1.103", "192.168.1.105", "192.168.1.107"],
      doors: [
         {
            name: "Cửa chính",
            ip: "192.168.1.103",
         },
         {
            name: "Cửa phụ",
            ip: "192.168.1.105",
         },
         {
            name: "Cửa sau",
            ip: "192.168.1.107",
         }
      ]
   },

   {
      name: "MCVGO-L4",
      ips: ["192.168.1.116"],
      doors: [
         {
            name: "MCVGO-L4",
            ip: "192.168.1.116",
         },
      ]
   },

   {
      name: "Kho",
      ips: ["192.168.1.114"],
      doors: [
         {
            name: "Kho",
            ip: "192.168.1.114",
         }
      ]
   },

   {
      name: "P.Kỹ Thuật",
      ips: ["192.168.1.109"],
      doors: [
         {
            name: "P.Kỹ Thuật",
            ip: "192.168.1.109",
         }
      ]
   },

   {
      name: "Control1-Trước",
      ips: ["192.168.1.201"],
      doors: [
         {
            name: "Control1 - Trước",
            ip: "192.168.1.201",
         }
      ]
   },

   {
      name: "Control1-Sau",
      ips: ["192.168.1.112"],
      doors: [
         {
            name: "Control1-Sau",
            ip: "192.168.1.112",
         }
      ]
   },

   {
      name: "Control 2",
      ips: ["192.168.1.111"],
      doors: [
         {
            name: "Control 2",
            ip: "192.168.1.111",
         }
      ]
   },

   {
      name: "Control 3",
      ips: ["192.168.1.108"],
      doors: [
         {
            name: "Control 3",
            ip: "192.168.1.108",
         }
      ]
   },
   {
      name: "Gate 1",
      ips: ["192.168.1.91"],
      doors: [
         {
            name: "Gate 1",
            ip: "192.168.1.91",
         }
      ]
   },
   {
      name: "Gate 2",
      ips: ["192.168.1.89"],
      doors: [
         {
            name: "Gate 2",
            ip: "192.168.1.89",
         }
      ]
   },

]